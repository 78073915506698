import CreditsAdd from "@/views/credits/Add.vue";
import CreditsHistory from "@/views/credits/History.vue";

export default [
  {
    path: "",
    name: "credits",
    redirect: "add",
    meta: {
      label: "Credits",
      breadcrumbs: ["home", "credits"],
    },
  },
  {
    path: "add",
    name: "credits-add",
    component: CreditsAdd,
    meta: {
      label: "Add Credits",
      breadcrumbs: ["home", "credits-add"],
    },
  },
  {
    path: "history",
    name: "credits-history",
    component: CreditsHistory,
    meta: {
      label: "Credit History",
      breadcrumbs: ["home", "credits-history"],
    },
  },
];
