<template>
  <TheShell action="login">
    <VpVueForm
      class="vp-mt-6 vp-pb-0 vp-mb-6"
      :save="login"
      :has-reset="false"
      :fields="fields"
      notify-message="Login Success"
      ref="item"
    >
      <template #default="{ state }">
        <VpField label="Email" name="Email" rules="required|email">
          <VpInput>
            <VpTextBox v-model="state.email" type="text" />
          </VpInput>
        </VpField>

        <VpField label="Password" name="Password" rules="required">
          <VyPassword
            class="input--md input--rounded"
            v-model="state.password"
            type="password"
          />
        </VpField>
      </template>

      <template #footer="{ isSaving }">
        <VpButtonMd
          :loading="isSaving"
          :disabled="isSaving"
          class="vp-w-full"
          type="submit"
          label="Login"
        />
      </template>
    </VpVueForm>
  </TheShell>
</template>

<script>
import qs from "qs";
import login from "./login.gql";
import { setToken, setCampaign, setCelloToken } from "plugins/utils";
import TheShell from "./_shell.vue";

export default {
  components: {
    TheShell,
  },
  data() {
    return {
      fields: ["email", "password"],
    };
  },

  mounted() {
    /**
     * If the token is provided in URL
     * Try to login with that token directly.
     */
    const token = this.$route.query?.token;
    const campaign = this.$route.query?.campaign;
    if (token) {
      this.afterLogin(token, campaign);
    }
  },

  methods: {
    login(id, data) {
      return this.$mutatePublic(login, {
        input: data,
      })
        .then(
          ({
            data: {
              login: { token, user, celloJwtToken },
            },
          }) => {
            this.afterLogin(token, user.campaigns[0]?.id, celloJwtToken);
            return true;
          }
        )
        .catch((error) => {
          const errorMessage = error?.message;
          this.$vayu.notify({
            title: "Error!",
            message: errorMessage
              ? errorMessage
              : "Somthing went wrong, please try again",
            state: "danger",
          });
        });
    },

    afterLogin(token, campaign, celloJwtToken) {
      setToken(token);
      setCampaign(campaign);
      setCelloToken(celloJwtToken);
      this.redirect();
    },

    redirect() {
      /**
       * If query parameter has the redirect key,
       * we should redirect to that path instead of default path.
       * Else if last path is stored in local storage, we should use it.
       * This is used in mobile checkout page from app
       */
      const lastPath = localStorage.getItem("vp-last-path");
      if (this.$route.query.redirect) {
        //This is browser level query
        const query = qs.parse(this.$route.query);

        const route = {
          name: query.redirect,
          params: query?.params, // this is vue params
          query: query?.query ?? undefined, // this is vue query
        };
        this.$router.push(route);
      } else if (lastPath) {
        this.$router.push({ path: lastPath });
      } else {
        this.$router.push({
          name: "home",
          query: {
            login: true,
          },
        });
      }
    },
  },
};
</script>
