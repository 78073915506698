<template>
  <div class="vp-py-10">
    <div class="vp-max-w-xs vp-w-full vp-mx-auto">
      <VpLoaderContainer v-if="redirecting" class="vp-py-12" loading />

      <!-- LOGIN -->
      <div v-else class="oauth__login">
        <img src="/img/logo-dark.png" class="vp-w-48" alt="Vepaar Logo" />

        <VpVueForm
          class="vp-mt-6"
          :save="login"
          :has-reset="false"
          :fields="fields"
          ref="item"
        >
          <template #default="{ state }">
            <VpField label="Email" name="Email" rules="required|email">
              <VpInput>
                <VpTextBox v-model="state.email" type="text" />
              </VpInput>
            </VpField>
            <VpField label="Password" name="Password" rules="required">
              <VpInput>
                <VpTextBox v-model="state.password" type="password" />
              </VpInput>
            </VpField>
          </template>

          <template #footer="{ isSaving }">
            <VpButtonMd
              :loading="isSaving"
              :disabled="isSaving"
              type="submit"
              label="Login"
            />
          </template>
        </VpVueForm>
      </div>
    </div>
  </div>
</template>

<script>
import oauth from "./oauth.gql";

export default {
  data() {
    return {
      fields: ["email", "password"],
      redirecting: false,
    };
  },

  methods: {
    login(id, data) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const { client_id, redirect_uri, response_type, state } = params;

      return this.$mutatePublic(oauth, {
        input: {
          ...data,
          clientId: client_id,
          redirectUri: redirect_uri,
          responseType: response_type,
          state: state,
        },
      }).then(({ data }) => {
        const { url } = data.oauthLogin;
        this.redirecting = true;
        window.location.replace(url);
        return data;
      });
    },
  },
};
</script>
