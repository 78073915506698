<template>
  <ApolloQuery
    ref="apolloQuery"
    v-bind="$attrs"
    :query="query"
    @result="loaded($event)"
  >
    <template #default="{ result: { error, data }, isLoading, query }">
      <slot v-if="error" name="error">
        <p>There was an error!</p>
        <p>{{ error }}</p>
      </slot>
      <slot v-else-if="isLoading" name="loading">
        <LoaderContainer loading class="vp-p-12" />
      </slot>
      <slot
        v-else
        name="default"
        :data="adaptor ? adaptor(data) : data"
        :loading="isLoading"
        :refresh="query.refetch"
      ></slot>
    </template>
  </ApolloQuery>
</template>

<script>
import LoaderContainer from "./loader-container.vue";
import gql from "graphql-tag";

export default {
  gql,
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    adaptor: {
      type: Function,
      default: null,
    },
  },

  components: {
    LoaderContainer,
  },

  computed: {
    query() {
      return gql`
        ${this.$attrs.query}
      `;
    },
  },

  methods: {
    loaded({ data }) {
      this.$emit("update:data", data);
      this.$emit("result", data);
    },
    refresh() {
      this.$refs.apolloQuery.getApolloQuery().refetch();
    },
  },
};
</script>
