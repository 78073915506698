<template>
  <AddCredits />
</template>

<script>
import AddCredits from "components/src/credits/add.vue";

export default {
  components: {
    AddCredits,
  },
};
</script>
