<template>
  <div class="vp-grid sm:vp-grid-cols-2 vp-gap-2">
    <div
      v-for="feature in value"
      :key="feature"
      class="vp-flex vp-items-center vp-gap-2 vp-font-medium"
    >
      <VpIcon
        :name="$options.Check"
        class="vp-w-4 vp-h-4 vp-text-success-500 vp-shrink-0"
      />
      <span class="vp-text-gray-500 sm:vp-whitespace-nowrap">{{
        feature
      }}</span>
    </div>
  </div>
</template>

<script>
import { Check } from "icons/icons.js";
export default {
  Check,
  props: {
    value: Array,
  },
};
</script>
