<template>
  <VyButton
    v-bind="$attrs"
    class="help__button vp-bg-[#E3EBFF] vp-text-xs button--md vp-text-[#304FFE] button--pill vp-w-full"
  />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style>
.help__button {
  .icon {
    width: 16px;
    height: 16px;
  }
}
</style>
