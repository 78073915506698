<template>
  <div>
    <div class="vp-pb-6">
      <DifferentChannel
        class="vp-p-4"
        :desc="`To invite users to your business, you must buy Paddle Premium plan from here. You can cancel your subscription from ${provider} and buy a new subscription for the same account from here.`"
      />
      <h2
        class="vp-text-2xl vp-font-bold vp-mt-6 vp-px-6 vp-text-secondary-500"
      >
        Invite your team members
      </h2>
      <div
        class="vp-text-gray-500 vp-text-xs vp-mt-2 vp-px-6 vp-flex vp-flex-col"
      >
        <span
          >Our subscription charges are per user. When you invite a user, so you
          will be charged immediately, even if the invitation is not accepted.
        </span>
        <span>
          Make sure to invite active users to maximize your investment.
          <a
            href="https://vepa.ar/docs"
            target="_blank"
            class="vp-text-black vp-font-bold"
          >
            Learn More</a
          ></span
        >
      </div>
    </div>
    <VpVueForm
      :fields="fields"
      :disabled="isDifferentChannel"
      :save="save"
      width="400px"
      class="vp-p-6"
      collection-cache="campaignUsers"
      :track="track"
      track-prefix="User"
    >
      <template #default="{ state }">
        <VpField name="name" label="Name" rules="required">
          <VpInput>
            <VpTextBox v-model="state.name" />
          </VpInput>
        </VpField>
        <VpField rules="required|email" name="Email" label="Email">
          <VpInput>
            <VpTextBox v-model="state.email" />
          </VpInput>
        </VpField>
        <VpField rules="required" label="Select Roles" name="Roles">
          <VpLoaderContainer :loading="loading" overlay>
            <CheckboxCard
              :options="allRoles"
              v-model="state.roleIds"
              class="vp-space-y-1"
            >
              <template #default="{ option, value }">
                <SelectCard
                  :title="option.label"
                  :active="value.includes(option.value)"
                >
                  <p class="vp-text-xs">{{ option.description }}</p>
                </SelectCard>
              </template>
            </CheckboxCard>
          </VpLoaderContainer>
        </VpField>
      </template>
      <template #footer>
        <VyButton
          type="submit"
          label="Save"
          :loading="isSaving"
          class="button--primary button--solid button--right button--lg button--split button--rounded"
        />
      </template>
    </VpVueForm>
  </div>
</template>

<script>
import CheckboxCard from "components/src/checkbox-card.vue";
import { mapGetters } from "vuex";

import SelectCard from "components/src/select-card.vue";
import inviteUser from "./inviteUser.gql";
import rolesgql from "./roles.gql";

import DifferentChannel from "../account/_DifferentChannel.vue";

export default {
  components: {
    SelectCard,
    CheckboxCard,
    DifferentChannel,
  },

  data() {
    return {
      allRoles: [],
      isSaving: false,
      fields: ["name", "email", { name: "roleIds", value: [] }],
      selectedRoles: [],
      loading: false,
    };
  },
  mounted() {
    this.getRoles();
  },
  computed: {
    ...mapGetters({
      isDifferentChannel: "subscription/isDifferentChannel",
      provider: "subscription/provider",
    }),

    track() {
      return {
        user: {
          key: "Invitation Email",
          value: (user) => (user && user.email) || "",
        },
        subscription: {
          key: "Invitation Subscription Plan",
          value: (subscription) =>
            (subscription && subscription.plan && subscription.plan.name) || "",
        },
        subscriptionPlanID: {
          resKey: "subscription",
          key: "Invitation Subscription Plan ID",
          value: (subscription) =>
            (subscription &&
              subscription.plan &&
              JSON.stringify(subscription.plan.id)) ||
            "",
        },
        invitation: {
          key: "Invited Email",
          value: (invitation) => (invitation && invitation.byEmail) || "",
        },
        roles: {
          key: "Invitation Roles",
          value: (roles) =>
            (roles && roles.map((res) => res.name).join(",")) || "",
        },
        campaignName: {
          resKey: "invitation",
          key: "Invitation Campaign Name",
          value: (invitation) => (invitation && invitation.businessName) || "",
        },
      };
    },
  },
  methods: {
    getRoles() {
      try {
        this.loading = true;
        this.$query(rolesgql).then(({ data }) => {
          const roles = data.roles.data;
          this.allRoles = roles.map((role) => {
            return {
              label: role.name,
              value: role.id,
              description: role.description,
            };
          });
          this.loading = false;
        });
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    save(id, data) {
      this.isSaving = true;
      return new Promise((resolve, reject) => {
        this.$vayu.confirm({
          name: "confirm",
          title: "Invite User Confirmation",
          message:
            "You'll be charged immediately a prorated amount for adding a member. Click on 'Yes' to send invitation.",
          ok: async ({ close }) => {
            close();
            const postData = {
              input: data,
            };
            this.$mutate(inviteUser, postData)
              .then(({ data }) => {
                this.$router.push({ name: "users" });
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              })
              .finally(() => (this.isSaving = false));
          },
          cancel: ({ close }) => {
            close();
            this.isSaving = false;
          },
        });
      });
    },
  },
};
</script>
