<template>
  <div>
    <VpVueForm
      :fields="fields"
      id="true"
      class="vp-p-6"
      :save="save"
      :get="get"
      width="400px"
    >
      <template #default="{ state, response }">
        <portal to="header">
          <div>
            <VyButton
              type="button"
              label="Wipe Data"
              :loading="wiping"
              @click.native="checkPermission('ACCOUNT', 'delete', wipe)"
              class="button--danger button--muted button--sm button--rounded"
            />
            <VyButton
              type="button"
              class="vp-ml-1 button--danger button--muted button--sm button--rounded"
              label="Delete Account"
              @click.native="
                checkPermission('ACCOUNT', 'delete', deleteAccount)
              "
            />
          </div>
        </portal>
        <VpField rules="required" label="Name" name="Name">
          <VpInput>
            <VpTextBox v-model="state.name" />
          </VpInput>
        </VpField>

        <VpField label="Email" name="Email">
          <VpInput>
            <VpTextBox disabled :value="response?.user.email" />
          </VpInput>
        </VpField>
      </template>
      <template #footer="{ isSaving }">
        <div class="vp-flex vp-w-full vp-justify-between">
          <VyButton
            :loading="isSaving"
            :disabled="isSaving"
            type="submit"
            label="Save"
            class="button--primary button--solid button--md button--rounded"
          />
          <VyButton
            type="button"
            :icon="$options.icons.Logout"
            class="vp-ml-1 button--danger button--muted button--md button--rounded"
            label="Logout"
            @click.native="handleLogout()"
          />
        </div>
      </template>
    </VpVueForm>
    <ConfirmLogout />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import update from "./update.gql";
import get from "./get.gql";
import wipeData from "./wipe-data.gql";
import { Logout } from "icons/icons.js";
import ConfirmLogout from "components/src/confirm-logout.vue";

export default {
  components: {
    ConfirmLogout,
  },
  icons: {
    Logout,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      wiping: false,
      fields: ["name"],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    get() {
      return this.$query(get).then((res) => {
        return {
          values: res.data.user,
          res,
        };
      });
    },
    handleLogout() {
      this.$vayu.confirm({
        name: "confirm-logout",
        title: "Logout!",
        ok: async ({ close }) => {
          this.$router.push({ name: "logout" });
          close();
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },
    save(id, data) {
      return this.$mutate(update, {
        input: data,
      }).then(({ data }) => {
        return data;
      });
    },

    deleteAccount() {
      this.$router.push({ name: "delete-account" });
      this.$track("Account Delete Clicked");
    },

    async wipe() {
      let message = `🚨🚨🚨\nAre you sure you want to DELETE ALL THE DATA? This action can not be reversed and your data will be permanently deleted.\n🚨🚨🚨\n\n👉 Click OK to confirm this action.\n👉 Click CANCEL to abort the action.`;
      if (!confirm(message)) return;

      this.wiping = true;
      try {
        await this.$mutate(wipeData);
        this.$vayu.notify({
          state: "success",
          title: "Success",
          message:
            "All the data related to your account has been deleted. Let's start fresh!",
          duration: 10000,
        });
        this.$router.push({ name: "logout" });
      } catch (err) {
        this.$vayu.notify({
          state: "danger",
          title: "Failed to wipe data!",
          message: "Please contact us to manually wipe the data.",
          duration: 10000,
        });
      }
      this.wiping = false;
    },
  },
};
</script>
