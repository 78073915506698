<template>
  <Query
    :query="$options.query"
    :variables="{ type: provider }"
    #default="{
      data: {
        creditProducts: { data },
      },
    }"
  >
    <div class="vp-grid md:vp-grid-cols-3 vp-gap-4">
      <div
        v-for="item in data"
        :key="`credit-product-2-${item.id}`"
        class="vp-border vp-rounded-lg vp-flex vp-flex-col vp-overflow-hidden hover:vp-shadow-xl"
      >
        <div
          class="vp-py-4 vp-pl-4 vp-pr-0 vp-flex vp-items-center vp-border-b vp-bg-gray-100"
        >
          <div class="vp-flex-grow-0 vp-mr-2">
            <Icon
              class="vp-w-16 vp-h-16 vp-text-warning-500"
              :name="$options.icons.Credit"
            />
          </div>
          <div class="vp-leading-none vp-text-3xl vp-font-bold">
            <p>${{ item.credit }}</p>
            <p class="vp-text-sm vp-text-gray-500 vp-leading-none">
              worth credits
            </p>
          </div>
          <div
            v-if="discount(item) > 0"
            class="vp-bg-gradient-to-r vp-from-warning-600 vp-to-warning-400 vp-inline-flex vp-px-6 vp-py-2 vp-uppercase vp-font-bold vp-flex-grow-0 vp-text-center vp-text-white vp-rounded-l-full vp-ml-auto"
          >
            Save {{ discount(item) }}%
          </div>
        </div>
        <div class="vp-flex-grow vp-px-4 vp-py-2 vp-border-b">
          <table class="vp-w-full">
            <tr class="vp-border-b vp-border-dashed vp-border-gray-300">
              <td>Price</td>
              <td class="vp-text-end vp-py-2">${{ item.price }}</td>
            </tr>
            <tr class="vp-border-b vp-border-dashed vp-border-gray-300">
              <td>Credits You Get</td>
              <td class="vp-text-end vp-py-2">${{ item.credit }}</td>
            </tr>
            <tr>
              <td>You save</td>
              <td
                class="vp-text-end vp-py-2"
                :class="{ 'vp-font-bold': discount(item) > 0 }"
              >
                ${{ item.credit - item.price }}
              </td>
            </tr>
          </table>
        </div>
        <div class="vp-p-4 vp-mt-auto vp-flex-grow-0">
          <VyButton
            type="button"
            :label="`Buy Now for $${item.price}`"
            @click.native="
              checkPermission('PURCHASE_CREDIT', 'upsert', () => {
                $emit('select', { productId: item.productId, name: item.name });
              })
            "
            :loading="loading == item.productId"
            class="button--primary button--solid button--lg button--rounded vp-w-full"
          />
        </div>
      </div>
    </div>
  </Query>
</template>

<script>
import { Credit } from "icons/icons.js";
import { mapGetters } from "vuex";

import Icon from "../icon.vue";
import Query from "../query.vue";
import query from "./products.gql";

export default {
  components: {
    Query,
    Icon,
  },

  icons: {
    Credit,
  },

  query,

  props: {
    loading: null,
  },

  data() {
    return {
      products: null,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    provider() {
      if (window.VepaarMobile?.platform == "ios") {
        return "apple";
      } else if (window.VepaarMobile?.platform == "android") {
        return "google";
      }
      return "paddle";
    },
  },

  methods: {
    discount(item) {
      return (item.credit * 100) / item.price - 100;
    },
  },
};
</script>
