<template>
  <TheShell action="signup" class="signup">
    <VpVueForm
      class="vp-mt-6 vp-pb-0 vp-mb-6"
      :save="signup"
      :has-reset="false"
      :fields="fields"
      notify-message="Signup Success"
      ref="item"
    >
      <template #default="{ state }">
        <VpField label="Name" name="Name" rules="required">
          <VpInput>
            <VpTextBox v-model="state.name" type="text" />
          </VpInput>
        </VpField>
        <VpField label="Email" name="Email" rules="required|email">
          <VpInput>
            <VpTextBox v-model="state.email" type="text" />
          </VpInput>
        </VpField>
        <VpField label="Password" name="Password" rules="required|min:5">
          <VyPassword
            class="input--md input--rounded"
            v-model="state.password"
          />
        </VpField>
      </template>

      <template #footer="{ isSaving }">
        <VpButtonMd
          :loading="isSaving"
          :disabled="isSaving"
          class="vp-w-full"
          type="submit"
          label="Create Account"
        />
      </template>
    </VpVueForm>
  </TheShell>
</template>

<script>
import {
  setToken,
  setCampaign,
  setCelloToken,
  setCelloUcc,
  getCelloUcc,
} from "plugins/utils";
import TheShell from "./_shell.vue";

import signup from "./signup.gql";

export default {
  components: {
    TheShell,
  },
  mounted() {
    if (this.$route.query.ucc) {
      // If user closes signup after cello invite link and comes back after 2 days, we will use cookie to apply referral program.
      setCelloUcc(this.$route.query.ucc);
    }
  },
  data() {
    return {
      fields: ["name", "email", "password"],
    };
  },

  methods: {
    signup(id, data) {
      let payload = {
        ...data,
      };

      const celloUcc = this.$route.query.ucc || getCelloUcc();

      if (celloUcc) {
        payload.celloReferralCode = this.$route.query.ucc;
      }

      return this.$mutatePublic(signup, {
        input: payload,
      }).then(
        ({
          data: {
            signup: { token, user, celloJwtToken },
          },
        }) => {
          window.tolt?.signup(user.email);
          const { campaigns } = user;
          this.afterSignup(token, campaigns[0]?.id, user, celloJwtToken);
          return true;
        }
      );
    },

    afterSignup(token, campaign, user, celloJwtToken) {
      try {
        if (token) {
          setToken(token);
          setCelloToken(celloJwtToken);
        }
        if (campaign) {
          setCampaign(campaign);
        }

        this.$fbp("CompleteRegistration", { content_name: user.email });
        this.$router.push({ name: "home", query: { signup: true } });
      } catch (error) {
        console.log("🚀 ~ afterSignup ~ error:", error);
      }
    },
  },
};
</script>
