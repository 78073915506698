import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// Public
import Signup from "@/views/public/Signup.vue";
import ForgotPassword from "@/views/public/ForgotPassword.vue";
import VerifyAccount from "@/views/public/VerifyAccount.vue";
import Login from "@/views/public/Login.vue";
import OAuth from "@/views/public/OAuth.vue";
import AppSumo from "@/views/public/AppSumo.vue";
import Download from "@/views/public/Download.vue";
import JoinBusiness from "@/views/public/JoinBusiness.vue";
import Logout from "@/views/Logout.vue";
import Redirect from "components/src/redirect.vue";

// Private
import Hydrate from "@/views/hydrate/index.vue";
import Home from "@/views/home/index.vue";
import Help from "@/views/Help.vue";
import CreditsIndex from "@/views/credits/Index.vue";
import AccountIndex from "@/views/account/Index.vue";
import account from "./account";

import credit from "./credit";

import { getToken, getOS } from "plugins/utils";
const os = getOS();

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      component: Hydrate,
      props: true,
      meta: {
        public: false,
      },
      children: [
        {
          path: "",
          name: "home",
          component: Home,
          meta: {
            label: "Home",
            breadcrumbs: ["home"],
          },
        },
        {
          path: "help",
          name: "help",
          component: Help,
          meta: {
            label: "Help",
            breadcrumbs: ["home", "help"],
          },
        },
        {
          path: "account",
          component: AccountIndex,
          children: account,
        },
        {
          path: "credits",
          children: credit,
          component: CreditsIndex,
        },
      ],
    },
    {
      path: "/redirect",
      name: "redirect",
      component: Redirect,
      meta: {
        public: true,
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: Signup,
      meta: {
        public: true,
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: {
        public: true,
      },
    },
    {
      path: "/verify-account",
      name: "verify-account",
      component: VerifyAccount,
      meta: {
        public: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        public: true,
      },
    },
    {
      path: "/oauth",
      name: "oauth",
      component: OAuth,
      meta: {
        public: true,
      },
    },

    {
      path: "/appsumo-onboarding",
      name: "appsumo-onboarding",
      component: AppSumo,
      meta: {
        public: true,
      },
    },

    {
      path: "/download",
      name: "download",
      component: Download,
      meta: {
        public: true,
      },
    },
    {
      path: "/join-business/:invitationId",
      name: "join-business",
      component: JoinBusiness,
      meta: {
        public: true,
      },
    },

    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: {
        public: true,
      },
    },
  ],
});

router.beforeEach(async (from, to, next) => {
  if (
    ["android", "ios"].includes(os) &&
    from.name != "download" &&
    from.name != "verify-account"
  ) {
    next({
      name: "download",
    });
  } else {
    if (from.params.id && from.params.id !== "+") {
      from.params.id = parseInt(from.params.id);
    }

    if (from.meta.public === true) {
      next();
    } else {
      if (getToken()) {
        next();
      } else {
        next({ name: "logout" });
      }
    }
  }
});

export default router;
