<template>
  <div></div>
</template>

<script>
import { getCelloToken } from "plugins/utils.js";
import { mapGetters } from "vuex";
const { VITE_CELLO_PRODUCT_ID } = import.meta.env;

export default {
  computed: {
    ...mapGetters({
      name: "user/name",
      email: "user/email",
    }),
  },
  mounted() {
    const token = getCelloToken();
    window.cello = window.cello || { cmd: [] };
    try {
      window.cello.cmd.push((cello) => {
        cello.boot({
          productId: VITE_CELLO_PRODUCT_ID,
          token,
          language: "en",
          productUserDetails: {
            firstName: "",
            lastName: "",
            fullName: this.name,
            email: this.email,
          },
        });
      });
    } catch (error) {
      console.error("Failed to boot cello:", error);
    }
  },

  beforeDestroy() {
    window.Cello("shutdown");
  },
};
</script>
