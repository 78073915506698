<template>
  <div v-if="plan" class="plan vp-text-start">
    <h5 class="vp-uppercase vp-text-primary-500 vp-font-bold">
      {{ data.name }}
    </h5>
    <div class="vp-mt-1 vp-items-end vp-flex">
      <h3 class="vp-leading-none vp-text-2xl vp-font-bold">{{ plan.price }}</h3>
      <p class="vp-text-gray-500">{{ plan.label }}</p>
    </div>

    <div class="buy vp-mt-2">
      <VyButton
        v-if="plan.subscription_plan.includes(active.planId)"
        label="Your Plan"
        class="button--gray button--muted button--sm button--rounded"
      />

      <VyButton
        v-else-if="!plan.subscription_plan.includes(0) && !isLegacy"
        :disabled="!canChange"
        label="Buy"
        :loading="loading"
        @click.native="
          checkPermission('PURCHASE_SUBSCRIPTION', 'upsert', select)
        "
        class="button--warning button--solid button--sm button--rounded paddle_button"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import update from "./subscription/update.gql";

export default {
  props: {
    duration: {
      type: String,
      default: "yearly",
    },
    data: Object,
    active: Object,
    mobile: {
      type: Boolean,
      default: false,
    },
    isLegacy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      checkPermission: "user/checkPermission",
      provider: "subscription/provider",
      canChange: "subscription/canChange",
      currentPlanId: "subscription/planId",
    }),

    plan() {
      return this.data.duration[this.duration];
    },

    price() {
      return parseFloat(this.plan.price.replace("$", ""));
    },
  },
  methods: {
    async select() {
      this.$vayu.confirm({
        name: "update-subscription",
        title: "Update Subscription",
        message: "Are you sure you want to update your subscription?",

        ok: async ({ close }) => {
          close();

          const latestPlanId = this.plan.product_id;
          const latestVepaarPlanId = this.plan.subscription_plan[0];
          const latestPlanName = `${this.data?.name.toUpperCase()} [${this.duration.toUpperCase()}]`;
          this.$track("Subscription Purchase Initiated", {
            "Current Plan ID": this.active.planId,
            "Current Plan": this.active.planName,
            "Current Plan Price": `$${this.active.price}`,
            "New Plan ID": latestPlanId,
            "New Plan": latestPlanName,
            "New Plan Price": `$${this.price}`,
            Platform: this.mobile ? "android" : "web",
          });
          this.loading = true;

          /**
           * When Free Plan, directly open Checkout
           * If already purchased plan, call backend API to upgrade.
           */
          if (this.currentPlanId == 0) {
            this.$emit("buy", {
              latestPlanId,
              latestPlanName,
              items: [{ price_id: latestPlanId }],
            });
          } else {
            this.$mutate(update, {
              input: {
                subscriptionPlan: latestVepaarPlanId,
              },
            })
              .then(({ data }) => {
                this.$vayu.notify({
                  state: "info",
                  message: data.updatePaddleSubscription.message,
                });
                this.$root.$emit("rehydrate", this.campaignId, true);
              })
              .catch((err) => {
                this.$vayu.notify({
                  state: "danger",
                  message: err.graphQLErrors[0].message,
                });
                this.$track("Web Subscription Error", {
                  "Current Plan ID": this.active.planId,
                  "Current Plan": this.active.planName,
                  "New Plan ID": latestPlanId,
                  "New Plan": latestPlanName,
                  Error: err,
                  Platform: this.mobile ? "android" : "web",
                });
              });
          }

          this.loading = false;
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },
  },
};
</script>
