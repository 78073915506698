<template>
  <TheShell action="verify-account">
    <div class="vp-mt-16 vp-mb-14">
      <!-- VERIFIED -->

      <VpAlertCard
        v-if="success"
        :emoji="null"
        title="✅ Account Verified!"
        color="success"
      >
        <p v-if="message" class="vp-text-sm vp-text-success-700">
          {{ message }}
        </p>
      </VpAlertCard>

      <!-- FAILED -->
      <VpAlertCard
        v-else
        emoji="🚫"
        title="Account Verification Failed!"
        color="danger"
      >
        <p>
          Don't worry. You can login to your account and request a fresh
          verification email.
        </p>
      </VpAlertCard>

      <VpButtonMd
        :to="{ name: 'login' }"
        class="vp-mt-6 vp-w-full"
        label="Login Now"
      />
    </div>
  </TheShell>
</template>

<script>
import TheShell from "./_shell.vue";

export default {
  components: {
    TheShell,
  },
  computed: {
    success() {
      return this.$route.query.success == 1;
    },
    message() {
      return this.$route.query.message;
    },
    email() {
      return this.$route.query.email;
    },
  },
};
</script>
