<template>
  <div v-if="requestCompleted" class="vp-p-6">
    <VpAlertCard color="success" title="✅ Got it!" width="600px" :emoji="null">
      <p class="vp-text-success-700 vp-text-base">
        We’ve received your request and will contact you within 48 hours.
      </p>
    </VpAlertCard>
  </div>
  <Reason
    v-else
    :options="$options.options"
    :loading="cancelling"
    button-label="Request For Cancellation"
    desc="But before you cancel, we would like to know why you're cancelling your subscription plan so we can improve."
    @submit="cancel"
    :feedback-required="true"
    :show-mobile-number="true"
  />
</template>

<script>
import createTicket from "./create-ticket.gql";
import Reason from "./_Reason.vue";
import subscription from "./subscription/mixin.js";

export default {
  options: [
    {
      label: "Can't find a feature I was looking for.",
      value: "lacks_feature",
    },
    {
      label: "It’s too expensive.",
      value: "expensive",
    },
    {
      label: "I've used it & don’t need it anymore.",
      value: "no_need",
    },
    {
      label: "I’m enjoying Vepaar & Will continue using the Free plan",
      value: "enjoying_freeplan",
    },
    {
      label: "Other",
      value: "other",
    },
  ],
  mixins: [subscription],
  data() {
    return {
      cancelling: false,
      requestCompleted: false,
    };
  },

  components: {
    Reason,
  },

  methods: {
    async cancel({ reason, feedback, mobileNumber }) {
      this.cancelling = true;
      const reasonLabel = this.$options.options.find(
        (item) => item.value === reason
      )?.label;

      return this.$mutatePublic(createTicket, {
        input: {
          numberWithCallingCode: mobileNumber,
          subject: "Cancel Subscription",
          priority: "high",
          description: `User Name: ${this.name}
User Email: ${this.email}
Mobile Number: ${mobileNumber}
Reason: ${reasonLabel}
Feedback: ${feedback}`,
        },
      })
        .then(({ data }) => {
          this.requestCompleted = data.createTicketMutation;
          this.$track("Subscription Cancel Requested", {
            "Store Id": this.storeId,
            Reason: reasonLabel,
            Feedback: feedback,
            "User Name": this.name,
            "User Email": this.email,
            "Mobile Number": mobileNumber,
          });
        })
        .finally(() => (this.cancelling = false));
    },
  },
};
</script>
