<template>
  <div
    class="vp-flex vp-justify-center vp-items-center vp-h-screen vp-overflow-auto vp-flex-col vp-p-8"
  >
    <img
      src="/img/logo-dark.png"
      width="240px"
      alt="Vepaar Logo"
      class="vp-mx-auto"
    />
    <div class="vp-max-w-md vp-text-center vp-mt-12 vp-text-lg">
      <p>Vepaar Web is not supported in mobile devices yet.</p>
      <p>
        To get the best experience and manage your business on the go, download
        our Mobile App.
      </p>
    </div>
    <div class="vp-mt-8 vp-flex vp-flex-col vp-space-y-3 vp-w-72">
      <a
        v-if="os != 'ios' || os == 'android'"
        href="https://vepa.ar/android"
        target="_blank"
      >
        <img
          class="vp-w-full"
          src="/img/google-play-badge.svg"
          alt="Download Android App"
        />
      </a>
      <a
        v-if="os != 'android' || os == 'ios'"
        href="https://vepa.ar/ios"
        target="_blank"
      >
        <img
          class="vp-w-full"
          src="/img/app-store-badge.svg"
          alt="Download iOS App"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { getOS } from "plugins/utils";

export default {
  data() {
    return {
      os: getOS(),
    };
  },
};
</script>
