<template>
  <History />
</template>

<script>
import History from "components/src/credits/history.vue";

export default {
  components: {
    History,
  },
};
</script>
