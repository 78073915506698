<template>
  <svg viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M18.1,22.4c-1.2,1.2-3.1,1.2-4.2,0l-2.1-2.1L7.5,16l2.1-2.1c1.2-1.2,3.1-1.2,4.2,0L16,16l6.9-6.9
	C24.8,7.2,23.4,4,20.8,4H7C5.3,4,4,5.3,4,7v18c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V12.5L18.1,22.4z"
    />
  </svg>
</template>

<script>
export default {
  name: "Vepaar",
};
</script>
