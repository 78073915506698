<template>
  <List
    :columns="columns"
    endpoint="creditHistory"
    :request-payload="{ query: $options.creditHistory }"
    cache="creditHistory"
    ref="list"
    title="Credit History"
    :search="false"
    :filter="false"
    :row-click="false"
    @res="$emit('refresh')"
  >
    <template #type="{ item: { type } }">
      <Icon
        v-tooltip="'Credit'"
        class="vp-block vp-w-6 vp-h-6 vp-text-success-500"
        :name="$options.icons.Add"
        v-if="type == 'credit'"
      />
      <Icon
        v-tooltip="'Debit'"
        class="vp-block vp-w-6 vp-h-6 vp-text-danger-500"
        :name="$options.icons.Remove"
        v-if="type == 'debit'"
      />
    </template>

    <template #amount="{ item: { type, amount } }">
      <p>
        <span v-if="type == 'debit'">-</span>
        <span>$</span>
        <span>{{ amount }}</span>
      </p>
    </template>

    <template #event="{ item: { event, mobileNo } }">
      <Event :value="event" :mobile="mobileNo" />
    </template>

    <template #remaining="{ item: { remaining } }">
      <p>
        <span v-if="remaining < 0">-</span>
        <span>$</span>
        <span>{{ Math.abs(remaining) }}</span>
      </p>
    </template>

    <template #createdAt="{ item: { createdAt } }">
      <Datetime class="vp-text-gray-500" :value="createdAt" />
    </template>
  </List>
</template>

<script>
import { Add, Remove } from "icons/icons.js";

import Datetime from "../datetime.vue";
import Icon from "../icon.vue";
import List from "../vp-list/index.vue";
import Event from "./_event.vue";
import creditHistory from "./history.gql";
export default {
  creditHistory,
  icons: {
    Add,
    Remove,
  },

  components: {
    Event,
    Icon,
    List,
    Datetime,
  },

  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        { name: "type", label: "Type", fix: true, type: "string" },
        { name: "event", label: "Event", type: "string" },

        {
          name: "amount",
          label: "Amount",
          type: "number",
        },
        {
          name: "remaining",
          label: "Remaining",
          type: "number",
        },
        { name: "createdAt", label: "Time", type: "string" },
      ];
    },
  },
};
</script>
