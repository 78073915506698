import AccountProfile from "@/views/account/Profile.vue";
import AccountDelete from "@/views/account/Delete.vue";
import AccountSubscription from "@/views/account/subscription/index.vue";
import PricingList from "@/views/account/PricingList.vue";
import AccountCancelSubscription from "@/views/account/CancelSubscription.vue";
import CurrentPlan from "@/views/account/CurrentPlan.vue";
import CurrentPricing from "@/views/account/CurrentPricing.vue";

import UsersIndex from "@/views/users/Index.vue";
import ChangePassword from "@/views/account/change-password.vue";

import user from "./user";

export default [
  {
    path: "",
    name: "account",
    redirect: "my-profile",
    meta: {
      label: "Account",
      breadcrumbs: ["home", "account"],
    },
  },
  {
    path: "users",
    component: UsersIndex,
    children: user,
  },
  {
    path: "my-profile",
    name: "my-profile",
    component: AccountProfile,
    meta: {
      label: "My Profile",
      breadcrumbs: ["home", "account", "my-profile"],
    },
  },
  {
    path: "change-password",
    name: "change-password",
    component: ChangePassword,
    meta: {
      label: "Change Password",
      breadcrumbs: ["home", "account", "change-password"],
    },
  },
  {
    path: "delete-account",
    name: "delete-account",
    component: AccountDelete,
    meta: {
      label: "Delete Account",
      breadcrumbs: ["home", "account", "my-profile", "delete-account"],
    },
  },
  {
    path: "subscription",
    name: "subscription",
    component: AccountSubscription,
    meta: {
      breadcrumbs: ["home", "account"],
    },
    children: [
      {
        path: "",
        redirect: "all",
      },
      {
        path: "crm",
        name: "subscription-crm",
        component: PricingList,
        props: true,
        meta: {
          label: "Change Plan",
          breadcrumbs: ["home", "account", "subscription-crm"],
        },
      },
      {
        path: "store",
        name: "subscription-store",
        component: PricingList,
        props: true,
        meta: {
          label: "Change Plan",
          breadcrumbs: ["home", "account", "subscription-store"],
        },
      },
      {
        path: "all",
        name: "subscription-all",
        component: PricingList,
        props: true,
        meta: {
          label: "Change Plan",
          breadcrumbs: ["home", "account", "subscription-all"],
        },
      },
    ],
  },
  {
    path: "your-plan",
    name: "your-plan",
    component: CurrentPlan,
    meta: {
      label: "Your Plan",
      breadcrumbs: ["home", "account", "your-plan"],
    },
  },
  {
    path: "legacy-plan",
    name: "legacy-plan",
    component: CurrentPricing,
    props: true,
    meta: {
      label: "Legacy Plan",
      breadcrumbs: ["home", "account", "your-plan", "legacy-plan"],
    },
  },
  {
    path: "cancel-subscription",
    name: "cancel-subscription",
    component: AccountCancelSubscription,
    meta: {
      label: "Cancel Subscription",
      breadcrumbs: ["home", "account", "cancel-subscription"],
    },
  },
];
