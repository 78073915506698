<template>
  <!-- Credits -->
  <LoaderContainer overlay :loading="$apollo.loading">
    <div class="vp-p-6 md:vp-p-8 vp-border-b">
      <p class="vp-leading-none">Available Credits</p>
      <h1
        class="vp-mt-3 vp-text-7xl vp-leading-none vp-block vp-text-transparent vp-font-bold"
      >
        <span
          class="vp-bg-gradient-to-r vp-from-primary-600 vp-to-primary-300 vp-bg-clip-text"
        >
          ${{ credits === null ? "##.##" : credits }}
        </span>
      </h1>
    </div>
    <div class="vp-p-6 md:vp-p-8">
      <p class="vp-mb-2 vp-leading-none">Add More Credits</p>
      <Products :loading="loading" @select="addCredits($event)" />
    </div>
  </LoaderContainer>
</template>

<script>
import LoaderContainer from "../loader-container.vue";
import { post } from "utils/port.js";
import { mapGetters } from "vuex";

import creditData from "./get.gql";
import Products from "./products.vue";

export default {
  data() {
    return {
      loading: false,
      credits: null,
    };
  },

  components: {
    Products,
    LoaderContainer,
  },

  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      email: "user/email",
    }),
  },

  apollo: {
    credits() {
      return {
        query: this.$gql(creditData),
        variables() {
          return {
            id: this.campaignId,
          };
        },
        update(res) {
          return res.campaign.credits;
        },
      };
    },
  },

  methods: {
    async addCredits({ productId, name }) {
      if (window.VepaarMobile) {
        post("credit-add", {
          productId,
        });
        this.$track("Credit Purchase Initiated Out", {
          "Product ID": productId,
          "Product Name": name,
          Credit: this.credits,
        });
      } else {
        this.loading = productId;
        this.$track("Credit Purchase Initiated", {
          "Product ID": productId,
          "Product Name": name,
          Credit: this.credits,
        });
        this.paddleCheckout({ productId, name });
        this.loading = false;
      }
    },

    paddleCheckout({ productId }) {
      window.Paddle.Checkout.open({
        customer: {
          email: this.email,
        },
        items: [
          {
            priceId: productId,
          },
        ],
        customData: {
          campaignId: this.campaignId,
        },
      });
    },
  },
};
</script>
