<template>
  <VpVueForm
    :fields="fields"
    class="vp-p-6"
    :save="save"
    :get="get"
    id="false"
    width="400px"
  >
    <template #default="{ state }">
      <VpField
        rules="required"
        label="Current Password"
        name="Current Password"
      >
        <VpInput>
          <VpTextBox v-model="state.currentPassword" type="password" />
        </VpInput>
      </VpField>
      <VpField rules="required|min:5" label="New Password" name="Password">
        <VpInput>
          <VyPassword
            class="input--md input--rounded vp-w-full"
            v-model="state.password"
          />
        </VpInput>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import change from "./change-password.gql";
export default {
  data() {
    return {
      fields: ["currentPassword", "password"],
    };
  },
  computed: {
    ...mapGetters({
      name: "user/email",
      email: "user/email",
    }),
  },
  methods: {
    get() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    save(id, data) {
      return this.$mutate(change, {
        input: data,
      }).then(({ data }) => {
        this.$track("User Password Changed", {
          "User Name": this.name,
          "User Email": this.email,
        });
        return data;
      });
    },
  },
};
</script>
