<template>
  <div class="vp-px-3 vp-mt-3">
    <VyTable class="subscription__table vp-w-full">
      <template #default>
        <tr>
          <th
            width="40%"
            class="vp-py-6 vp-pr-12 vp-font-normal vp-sticky vp-top-0 vp-bg-white vp-align-top"
          >
            <VpSwitch
              on-value="yearly"
              off-value="monthly"
              v-model="duration"
            />
            <VpField
              v-if="provider !== 'appsumo'"
              inline
              class="vp-mt-2"
              label="Billed Annually"
              note="All prices are exclusive of taxes."
            >
            </VpField>
          </th>
          <th
            class="vp-py-6 vp-pr-12 vp-sticky vp-top-0 vp-bg-white vp-align-top"
            width="20%"
            v-for="(plan, index) in plans"
            :key="`plan-${index}`"
          >
            <Plan
              :active="{ planId, planName, price }"
              :duration="duration"
              :data="plan"
              @buy="checkout($event)"
              :isLegacy="hasLegacyPlan"
            />
          </th>
        </tr>

        <tr
          class="vp-border-t hover:vp-bg-gray-100"
          v-for="(feature, key) in features"
          :key="key"
        >
          <td class="vp-py-2">{{ key }}</td>
          <td v-for="(attr, index) in feature" :key="`attr-${index}`">
            <span v-if="attr === true">✅</span>
            <span v-else-if="attr === false">❌</span>
            <span v-else>{{ attr }}</span>
          </td>
        </tr>
      </template>
    </VyTable>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import subscription from "./subscription/mixin.js";

export default {
  mixins: [subscription],
  props: {
    hasLegacyPlan: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.isLegacyPlan = this.hasLegacyPlan;
    this.get(this.planType);
  },

  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      celloReferralCode: "user/celloReferralCode",
      isLoggedIn: "user/isLoggedIn",
    }),
    planType() {
      return this.$route.name;
    },
  },

  methods: {
    cancel() {
      this.$router.push({ name: "cancel-subscription" });
      this.$track("Subscription Cancel Clicked");
    },
    checkout({ items }) {
      let payload = {
        items,
        customer: {
          email: this.email,
        },
        customData: {
          campaignId: this.campaignId,
          tolt_referral: window.tolt_referral,
        },
      };

      // Cello Referral program discount

      if (this.celloReferralCode) {
        if (this.duration === "yearly")
          payload.discountId = "dsc_01j1c1b228sgjf7qgaxtwqpk6v";
        else payload.discountId = "dsc_01j0tsyby1tf78mdt8crt0fkm7"; // Paddle discount ID
      }

      window.Paddle.Checkout.open(payload);
    },
  },
};
</script>
