<template>
  <VpListLayout
    :on-add="redirect()"
    addLabel="Invite users"
    feature="INVITE_USER"
  >
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="campaignUsers"
      cache="campaignUsers"
      :request-payload="{ query: $options.campaignUsers }"
      :search="false"
      class="h-100"
      ref="list"
      title="Users"
      :loading="loading"
      :rowClick="false"
      feature="BUSINESS"
    >
      <template #name="{ item }">
        <div class="grid grid--1 g-2">
          <p class="pl-3 mt-0">{{ item.user?.name }}</p>
        </div>
      </template>

      <template #email="{ item }">
        <div class="grid grid--1 g-2">
          <p class="pl-3 mt-0">{{ item.user?.email }}</p>
        </div>
      </template>

      <template #role="{ item }">
        <p class="pl-3 mt-0">
          {{ handleRoles(item.roles) }}
        </p>
      </template>

      <template #mobileNo="{ item }">
        <div class="grid grid--1 g-2">
          <p class="pl-3 mt-0">{{ item.mobile?.internationalFormat }}</p>
        </div>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.invitation?.status" />
      </template>

      <template #invitedBy="{ item }">
        <VpStatus :value="item.invitation?.byName" />
      </template>

      <template #actions="{ item }">
        <VyButton
          v-if="item.invitation.uuid"
          :icon="$options.icons.Delete"
          shape="square"
          size="sm"
          color="danger"
          @click.native="del(item)"
          class="button--danger button--muted button--square button--sm button--rounded"
        />
      </template>
    </VpList>
    <LegacyModal />
  </VpListLayout>
</template>

<script>
import { Delete } from "icons/icons.js";
import { mapGetters } from "vuex";
import campaignUsers from "@/graph/teams/campaignUsers.gql";

import deleteCampaignUser from "./deleteCampaignUser.gql";

import LegacyModal from "components/src/account/legacy-modal.vue";

export default {
  campaignUsers,
  icons: {
    Delete,
  },
  components: {
    LegacyModal,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          name: "_index",
          label: "#",
          width: "50px",
          type: "string",
        },
        { name: "name", type: "string" },
        { name: "email", type: "string" },
        { name: "role", type: "string" },
        { name: "mobileNo", type: "string" },
        { name: "invitedBy", type: "string" },
        { name: "status", type: "string" },
        { name: "actions", type: "string" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userId: "user/id",
      userName: "user/name",
      checkSubscription: "user/checkSubscription",
    }),
    isPlanWantUpgrade() {
      return !this.checkSubscription("INVITE_USER")?.hasAccess;
    },
  },
  methods: {
    handleRoles(data) {
      const roles = data.map((role) => {
        return role.name;
      });
      return roles.join(", ");
    },
    redirect() {
      return () => {
        if (this.isPlanWantUpgrade) {
          this.$vayu.modal.open("pro-feature-modal", {
            featureName: "INVITE_USER",
          });
        } else {
          this.$router.push({ name: "invite" });
        }
      };
    },
    async del(item) {
      this.$vayu.confirm({
        name: "delete",
        title: "Delete invite?",
        message: "Are you sure you want to delete this invitation? ",

        ok: async ({ close }) => {
          this.loading = true;
          close();
          return this.$mutate(deleteCampaignUser, {
            id: parseInt(item.id),
          })
            .then(() => {
              this.$track("Invited User Deleted", {
                Name: item.user.name,
                Email: item.user.email,
                Role: item.roles.map((res) => res.name).join(","),
                "Admin Email": item.invitation.byEmail,
              });
              this.$refs.list.refresh();
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$vayu.notify({
                state: "danger",
                message: err,
              });
            });
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },
  },
};
</script>
