<template>
  <div>
    <h2 class="vp-text-[26px] vp-font-semibold">{{ title }}</h2>
    <p class="vp-text-gray-500 vp-mt-1 vp-text-sm">
      {{ desc }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
};
</script>
