<template>
  <div>
    <!-- CHAT -->

    <TheCard
      title="Contact Us"
      desc="Our team is available on Email. Drop a message and we will try to get back to you at earliest."
    >
      <template #action>
        <HelpButton
          :icon="$options.icons.Email"
          label="Mail Us"
          :href="emailLink"
        />
      </template>
    </TheCard>

    <!-- DEMO -->
    <TheCard
      title="Schedule a Demo"
      desc="Book a demo call and get help with setting up your account at your preferred time."
    >
      <template #action>
        <HelpButton
          :icon="$options.icons.Calendar"
          label="Book a Slot"
          @click.native="
            goToLink('Schedule a Demo', 'https://vepa.ar/book-demo')
          "
        />
      </template>
    </TheCard>

    <!-- Follow Us -->
    <TheCard title="Follow Us" desc="Why not join us in our splendid journey?">
      <template #action>
        <div class="vp-flex vp-items-center vp-gap-2">
          <HelpButton
            :icon="$options.icons.Facebook"
            @click.native="followUsOn('Facebook', 'https://vepa.ar/facebook')"
          />
          <HelpButton
            :icon="$options.icons.Twitter"
            @click.native="followUsOn('Twitter', 'https://vepa.ar/twitter')"
          />
          <HelpButton
            :icon="$options.icons.Instagram"
            @click.native="followUsOn('Instagram', 'https://vepa.ar/instagram')"
          />
        </div>
      </template>
    </TheCard>

    <!-- Facebook  -->
    <TheCard
      title="Discuss & Get Help"
      desc="We've a large community group on Facebook. Join to get quick help from the people & discuss about the product."
    >
      <template #action>
        <HelpButton
          :icon="$options.icons.Facebook"
          label="Join Facebook Group"
          @click.native="
            goToLink('Join Facebook Group', 'https://vepa.ar/fb-group')
          "
        />
      </template>
    </TheCard>

    <!-- LEARN -->
    <TheCard
      title="Documentation"
      desc="Get most out of the Vepaar. Know all the features available in Vepaar & learn how to use them."
    >
      <template #action>
        <HelpButton
          :icon="$options.icons.Info"
          label="View User Guide"
          @click.native="goToLink('View User Guide', 'https://vepa.ar/docs')"
        />
      </template>
    </TheCard>

    <TheCard
      title="Youtube"
      desc="Discover how to make the most of Vepaar with our youtube channel."
    >
      <template #action>
        <HelpButton
          :icon="$options.icons.Youtube"
          label="View Channel"
          @click.native="
            goToLink('Visit Youtube Channel', 'https://vepa.ar/youtube')
          "
        />
      </template>
    </TheCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HelpButton from "./_button.vue";
import TheCard from "./_card.vue";

import {
  Info,
  Email,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Calendar,
} from "icons/icons.js";
export default {
  icons: {
    Info,
    Email,
    Facebook,
    Youtube,
    Twitter,
    Instagram,
    Calendar,
  },
  components: {
    HelpButton,
    TheCard,
  },
  computed: {
    ...mapGetters({
      name: "user/name",
      email: "user/email",
      planName: "subscription/planName",
      isActive: "subscription/isActive",
      isLoggedIn: "user/isLoggedIn",
    }),

    emailLink() {
      const text = `
Name: ${this.name}
Email: ${this.email}
Plan: ${this.planName}`;
      const encodedText = encodeURI(text);
      return `mailto:support@vepaar.com?subject=Hello&body=${encodedText}`;
    },
  },

  data() {
    return {
      team: [
        {
          name: "Harsh Vyas",
          desc: "Product Expert",
          forPro: false,
          profilePicture: {
            url: "/img/harsh.jpg",
          },
        },
      ],
    };
  },
  methods: {
    goToLink(type, link) {
      if (this.isLoggedIn) {
        this.$track(`Clicked ${type}`, {
          Location: "Help Section",
        });
      }
      window.open(link, "_blank");
    },

    followUsOn(type, link) {
      if (this.isLoggedIn) {
        this.$track("Clicked Follow Us", {
          Location: "Help Section",
          "Follow Us On": type,
        });
      }
      window.open(link, "_blank");
    },
  },
};
</script>
