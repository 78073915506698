<template>
  <VpModal
    title="Legacy plan"
    _class="vp-w-2/3 vp-h-5/6"
    name="current-pricing"
  >
    <div class="vp-px-3">
      <PricingList :hasLegacyPlan="true"></PricingList>
    </div>
  </VpModal>
</template>
<script>
import subscription from "./subscription/mixin.js";
import PricingList from "@/views/account/PricingList.vue";

export default {
  name: "CurrentPricing",
  mixins: [subscription],
  props: ["selectedValue"],
  watch: {
    selectedValue: function (newVal) {
      this.get(newVal);
    },
  },
  created() {
    this.get(this.selectedValue);
  },
  components: {
    PricingList,
  },
};
</script>
<style scoped>
.field__input {
  align-items: start;
}
</style>
