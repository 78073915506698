<template>
  <div class="vp-p-6 vp-max-w-lg">
    <fieldset
      :disabled="!checkPermission('ACCOUNT', 'delete') || provider === 'apple'"
    >
      <VpPageHeader :title="`${name}, we're sad! 😔`">
        <p>{{ desc }}</p>
      </VpPageHeader>

      <div class="vp-grid vp-gap-4">
        <VpField class="vp-mt-6" label="Kindly let us know the reason:">
          <div>
            <VpRadioGroup
              stack
              name="reason"
              v-model="reason"
              :options="options"
            />
          </div>
        </VpField>

        <VpField label="Any other feedback?" :optional="!feedbackRequired">
          <VpTextArea :rows="6" v-model="feedback"></VpTextArea>
        </VpField>

        <VpField
          v-if="showMobileNumber"
          label="Share a number to contact you:"
          note="We may try to contact you with possible discounts and feature requests."
        >
          <VpInput>
            <VpTextBox type="number" v-model="mobileNumber" />
          </VpInput>
        </VpField>

        <div class="vp-mt-6">
          <VyButton
            type="submit"
            :disabled="disableButton"
            :label="'💔' + ' ' + buttonLabel"
            @click.native="
              checkPermission(
                'ACCOUNT',
                'delete',
                $emit('submit', { reason, feedback, mobileNumber })
              )
            "
            :loading="loading"
            class="button--danger button--muted button--md button--rounded"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { getMobileNumbers } from "utils/presets";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      reason: null,
      feedback: null,
      numbers: [],
      mobileNumber: null,
      gettingMobileNumbers: false,
    };
  },
  props: {
    desc: String,
    options: Array,
    buttonLabel: String,
    loading: Boolean,
    feedbackRequired: {
      type: Boolean,
      default: false,
    },
    showMobileNumber: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      name: "user/name",
      checkPermission: "user/checkPermission",
      provider: "subscription/provider",
    }),
    disableButton() {
      if (!this.reason) {
        return true;
      }
      if (this.feedbackRequired && !this.feedback) {
        return true;
      }
      if (this.showMobileNumber && !this.mobileNumber) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    if (
      this.showMobileNumber &&
      this.checkPermission("CAMPAIGN_MOBILE", "read")
    ) {
      this.getNumbers();
    }
  },

  methods: {
    getNumbers() {
      this.gettingMobileNumbers = true;
      return getMobileNumbers()
        .then((res) => {
          this.mobileNumber = res.length > 0 ? res[0].mobile : null;
        })
        .finally(() => (this.gettingMobileNumbers = false));
    },
  },
};
</script>
