<template>
  <div v-if="isDifferentChannel">
    <VpAlertCard color="warning" emoji="⚠️" desc="Your Plan">
      <p>Your subscription is issued by:</p>
      <h4 class="vp-text-lg vp-font-bold" v-if="provider == 'apple'">
        Apple App Store
      </h4>
      <h4 class="vp-text-lg vp-font-bold" v-else-if="provider == 'nexarc'">
        Tata nexarc
      </h4>
      <h4 class="vp-text-lg vp-font-bold" v-else-if="provider == 'google'">
        Google Play Store
      </h4>
      <h4 class="vp-text-lg vp-font-bold vp-capitalize" v-else>
        {{ provider }}
      </h4>

      <p>
        {{ desc }}
      </p>
      <p>Please contact us in case of any queries or help.</p>
    </VpAlertCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    desc: {
      type: String,
      default:
        "If you wish to modify/cancel the subscription, you'll need to use the same platform.",
    },
  },
  computed: {
    ...mapGetters({
      provider: "subscription/provider",
      isDifferentChannel: "subscription/isDifferentChannel",
    }),
  },
};
</script>
