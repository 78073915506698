<template>
  <div
    class="vp-flex vp-items-center vp-justify-center vp-shrink-0 vp-rounded-2xl vp-bg-primary-100 vp-bg-opacity-30 vp-w-20 vp-h-20 vp-p-5"
  >
    <VpIcon :name="$options.Crm" class="vp-text-primary-500 vp-text-6xl" />
  </div>
</template>

<script>
import { Crm } from "icons/icons.js";
export default {
  Crm,
};
</script>
