import InviteUsers from "@/views/users/Item.vue";
import UsersList from "@/views/users/List.vue";
export default [
  {
    path: "",
    name: "users",
    component: UsersList,
    meta: {
      label: "Users",
      breadcrumbs: ["home", "account", "users"],
    },
  },
  {
    path: "invite",
    name: "invite",
    component: InviteUsers,
    meta: {
      label: "Invite",
      breadcrumbs: ["home", "account", "users", "invite"],
    },
  },
];
