import bus from "./bus.js";
let port;
let pendingPortEvents = [];

export const post = (action, payload = {}) => {
  if (port) {
    port.postMessage(JSON.stringify({ action, payload }));
  } else {
    pendingPortEvents.push({ action, payload });
  }
};

/**
 *  https://inappwebview.dev/docs/javascript/communication/#Web-Message-Channels
 */
window.addEventListener(
  "message",
  function (event) {
    if (event.data == "capturePort") {
      // capture port2 coming from the Dart side
      if (event.ports[0] != null) {
        // the port is ready for communication,
        // so you can use port.postMessage(message); wherever you want
        port = event.ports[0];

        if (pendingPortEvents.length > 0) {
          pendingPortEvents.forEach(({ action, payload }) => {
            post(action, payload);
          });
          pendingPortEvents = [];
        }

        // To listen to messages coming from the Dart side, set the onmessage event listener
        port.onmessage = function (event) {
          // event.data contains the message data coming from the Dart side
          const { action, payload } = JSON.parse(event.data);
          bus.$emit(action, payload);
        };
      }
    }
  },
  false
);

export default port;
