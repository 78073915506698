<template>
  <VyConfirm
    :header="false"
    name="confirm-logout"
    _class="vp-w-1/3 vp-rounded-xl"
  >
    <template #default>
      <p class="vp-p-6">
        {{ desc }}
      </p>
    </template>
    <template #footer="{ cancel, ok, okLoading }">
      <div class="vp-flex vp-justify-end vp-space-x-2 vp-px-6 vp-pb-6">
        <VyButton
          label="Cancel"
          class="button--lg button--muted button--gray button--rounded"
          @click.native="cancel()"
        />
        <VyButton
          label="Logout"
          class="button--lg button--danger button--rounded button--solid"
          :loading="okLoading"
          @click.native="ok()"
        />
      </div>
    </template>
  </VyConfirm>
</template>

<script>
export default {
  props: {
    desc: {
      type: String,
      default: "Are you sure want to logout?",
    },
  },
};
</script>
