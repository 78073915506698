<template>
  <VyCheckboxGroup
    v-bind="$attrs"
    :options="options"
    @input="$emit('input', $event)"
    :value="value"
  >
    <template #default="{ option, value, input }">
      <button type="button" @click="input(option.value)" class="vp-w-full">
        <slot
          name="default"
          :option="option"
          :value="value"
          :input="input"
        ></slot>
      </button>
    </template>
  </VyCheckboxGroup>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: Array,
  },
};
</script>
