<template>
  <div
    class="vp-bg-primary-50 vp-bg-opacity-50 vp-w-full vp-h-full vp-flex vp-justify-center vp-overflow-scroll"
  >
    <div class="vp-max-w-5xl vp-p-4 lg:vp-p-0 lg:vp-mx-auto">
      <div class="vp-my-11 vp-font-semibold">
        <h2 class="vp-text-3xl">Get Help</h2>
        <p class="vp-text-gray-500">We are here to help you!</p>
      </div>
      <Help class="vp-grid md:vp-grid-cols-2 lg:vp-grid-cols-3 vp-gap-4" />
    </div>
  </div>
</template>

<script>
import Help from "components/src/help/index.vue";

export default {
  components: {
    Help,
  },
};
</script>
