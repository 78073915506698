<template>
  <div>
    <VpAlertCard
      v-if="provider === 'apple'"
      class="vp-mt-4 vp-ml-4"
      emoji="⚠️"
      title="Before You Delete!"
      color="warning"
    >
      <p>You have an active subscription plan - {{ planName }}</p>
      <p>
        Make sure to manually cancel it from Apple App Store before deleting
        your account to avoid any future charges.
      </p>
      <p>
        <a
          href="https://support.apple.com/billing"
          class="vp-underline"
          target="_blank"
          >Click here</a
        >
        to cancel subscription
      </p>
    </VpAlertCard>
    <Reason
      :options="$options.options"
      :loading="deleting"
      button-label="Delete My Account"
      desc="But before you go, we would like to know why you're deleting your account so we can improve."
      @submit="del"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import deleteAccount from "./delete-account.gql";
import Reason from "./_Reason.vue";

export default {
  options: [
    {
      label: "Can't find a feature I was looking for.",
      value: "lacks_feature",
    },
    {
      label: "It’s too expensive.",
      value: "expensive",
    },
    {
      label: "I switched to other app.",
      value: "switched",
    },
    {
      label: "I've used it & don’t need it anymore.",
      value: "no_need",
    },
    {
      label: "I've privacy concerns.",
      value: "privacy_issue",
    },
    {
      label: "Other",
      value: "other",
    },
  ],
  data() {
    return {
      deleting: false,
    };
  },

  components: {
    Reason,
  },

  computed: {
    ...mapGetters({
      provider: "subscription/provider",
      planName: "subscription/planName",
    }),
  },
  methods: {
    async del({ reason, feedback }) {
      let message = `🚨🚨🚨\nAre you sure you want to DELETE YOUR ACCOUNT AND RELATED DATA? This action can not be reversed. Your account and data will be permanently deleted.\n🚨🚨🚨\n\n👉 Click OK to confirm this action.\n👉 Click CANCEL to abort the action.`;
      if (!confirm(message)) return;

      try {
        this.deleting = true;

        await this.$mutate(deleteAccount);

        this.$vayu.notify({
          state: "success",
          title: "Success",
          message:
            "All the data & your account has been deleted. Hope to see you soon!",
          duration: 10000,
        });
        const reasonLabel = this.$options.options.find(
          (item) => item.value === reason
        )?.label;
        this.$track("Account Deleted", {
          Reason: reasonLabel,
          Feedback: feedback,
        });
        this.$router.push({ name: "logout" });
      } catch (err) {
        this.$vayu.notify({
          state: "danger",
          title: "Failed to delete account!",
          message: "Please contact us to manually delete the account.",
          duration: 10000,
        });
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>
