<template>
  <div class="vp-flex vp-flex-col vp-py-2">
    <p class="vp-leading-none vp-text-xs">{{ value }}</p>
    <p class="vp-text-xs vp-text-gray-500 vp-leading-none vp-mt-1">
      {{ mobile.internationalFormat }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    mobile: Object,
  },
};
</script>
