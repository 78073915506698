<template>
  <div v-if="isCancellationPending">
    <VpAlertCard color="warning" emoji="⚠️" desc="Your Plan">
      <p>Your subscription is scheduled to cancel on:</p>
      <h4 class="vp-text-lg vp-font-bold">
        <VpDatetime :relative="false" :value="validity" />
      </h4>

      <p class="vp-mt-2">
        You can use all the features available in your plan until your plan
        expires.
      </p>
      <p>
        You can update your plan after the cancellation. Please contact us in
        case of any queries or help.
      </p>
    </VpAlertCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      validity: "subscription/validity",
      isCancellationPending: "subscription/isCancellationPending",
    }),
  },
};
</script>
