<template>
  <div
    class="vp-flex vp-text-left vp-items-start vp-flex-nowrap vp-p-4 vp-rounded-md vp-cursor-pointer vp-border vp-border-gray-200"
    :class="{
      'hover:vp-border-gray-300 vp-border-gray-100': !active,
      'vp-border-success-500': active,
      'vp-pointer-events-none vp-opacity-50': disabled,
    }"
  >
    <div>
      <VpIcon
        v-if="active"
        :name="$options.icons.RadioChecked"
        class="vp-text-success-500 vp-w-6 vp-h-6"
      />
      <VpIcon
        v-else
        :name="$options.icons.Radio"
        class="vp-text-gray-400 vp-w-6 vp-h-6"
      />
    </div>
    <div class="vp-pl-4 vp-flex-auto">
      <h5 class="vp-font-bold">{{ title }}</h5>
      <VyButton
        v-if="badge"
        :label="badge"
        class="vp-mt-1 vp-mb-2 button--muted button--xs button--pill"
        :class="$options.buttonClass[badgeColor]"
      />
      <div class="vp-text-gray-500">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { Radio, RadioChecked } from "icons/icons.js";

const buttonClass = {
  success: "button--success",
  danger: "button--danger",
  gray: "button--gray",
};
export default {
  icons: {
    Radio,
    RadioChecked,
  },
  buttonClass,
  props: {
    active: Boolean,
    title: String,
    badge: String,
    disabled: Boolean,
    badgeColor: {
      type: String,
      default: "gray",
    },
  },
};
</script>
