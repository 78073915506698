<template>
  <TheShell action="forgot-password" class="forgot-password">
    <div class="vp-my-6">
      <!-- CODE SENT -->
      <VpVueForm
        key="reset"
        :save="reset"
        v-if="codeSent"
        :fields="resetFields"
        class="vp-mt-6 vp-pb-0 vp-mb-6"
        :notify="false"
      >
        <template #default="{ state }">
          <p class="vp-text-warning-700">
            We have sent you an email with a Passcode.
            <br />To reset a password, you'll need to enter a passcode and a new
            password.
          </p>

          <VpField label="Email">
            <h4 class="vp-text-lg vp-font-bold">
              {{ email || "mail@theharsh.in" }}
            </h4>
          </VpField>

          <VpField label="Passcode" name="Passcode" rules="required">
            <VpInput>
              <VpTextBox v-model="state.code" />
            </VpInput>
          </VpField>
          <VpField rules="required|min:5" name="Password" label="New Password">
            <VpInput>
              <VyPassword
                class="input--md input--rounded vp-w-full"
                v-model="state.password"
              />
            </VpInput>
          </VpField>
        </template>

        <template #footer="{ isSaving }">
          <VpButtonMd
            :disabled="isSaving"
            class="vp-w-full"
            label="Change Password"
            :loading="isSaving"
            type="submit"
          />
        </template>
      </VpVueForm>

      <!-- REQUEST CODE -->
      <VpVueForm
        key="request"
        :save="request"
        v-else
        :fields="requestFields"
        class="vp-mt-6 vp-pb-0 vp-mb-6"
        :notify="false"
      >
        <template #default="{ state }">
          <VpField
            rules="required|email"
            label="Account Email"
            name="Account Email"
            note="We'll send you an email with password reset instructions."
          >
            <VpInput>
              <VpTextBox v-model="state.email" />
            </VpInput>
          </VpField>
        </template>

        <template #footer="{ isSaving }">
          <VpButtonMd
            :disabled="isSaving"
            label="Reset Password"
            class="vp-w-full"
            type="submit"
            :loading="isSaving"
          />
        </template>
      </VpVueForm>
    </div>
  </TheShell>
</template>

<script>
import forgotPassword from "./forgot-password.gql";
import resetPassword from "./reset-password.gql";
import TheShell from "./_shell.vue";

export default {
  components: {
    TheShell,
  },
  data() {
    return {
      codeSent: false,
      email: null,
      requestFields: ["email"],
      resetFields: ["code", "password"],
    };
  },

  methods: {
    request(id, data) {
      return this.$mutatePublic(forgotPassword, {
        input: data,
      }).then(() => {
        this.email = data.email;
        this.codeSent = true;
        return true;
      });
    },

    reset(id, data) {
      return this.$mutatePublic(resetPassword, {
        input: { ...data, email: this.email },
      }).then(() => {
        this.$router.push({
          name: "login",
        });
        this.$vayu.notify({
          title: "Password Changed",
          message: "Now you can login with your new password.",
          duration: 5000,
          state: "success",
        });
        return true;
      });
    },
  },
};
</script>
