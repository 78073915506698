<template>
  <div
    class="vp-overflow-scroll vp-h-full vp-flex vp-justify-center vp-bg-primary-50 vp-bg-opacity-50"
  >
    <div class="vp-max-w-2xl vp-my-11">
      <TheHeader />

      <ProductCards />
    </div>
    <CelloWidget />
  </div>
  <!-- Help section -->
</template>

<script>
import TheHeader from "./_header.vue";
import ProductCards from "./product/_cards.vue";
import CelloWidget from "components/src/cello-widget.vue";
export default {
  components: {
    TheHeader,
    ProductCards,
    CelloWidget,
  },
};
</script>
