<template>
  <div id="app" class="vp-w-full vp-h-screen">
    <RouterView />

    <VyNotificationPort
      name="default"
      class="port--top port--right vp-p-4 vp-z-50"
    />
    <PortalTarget
      id="slideover-container"
      name="slideover-container"
      multiple
    />

    <VyPort name="modal" has-overlay class="port--middle port--center z-40" />

    <VyPort name="confirm" class="port--middle port--center z-50" has-overlay />
    <ConfirmWrapper />
    <ConfirmDelete />
    <VyConfirm
      name="default"
      _class="vp-w-1/2 vp-rounded-xl"
      title="Are you sure?"
      message="Are you sure?"
    />
    <UpdateSubscription />
    <ProFeatureModal />
  </div>
</template>

<script>
import ConfirmWrapper from "components/src/confirm/index.vue";
import ConfirmDelete from "components/src/confirm/delete.vue";
import UpdateSubscription from "@/views/account/subscription/update-subscription.vue";
import ProFeatureModal from "components/src/pro-feature-modal.vue";

export default {
  components: {
    ProFeatureModal,
    ConfirmWrapper,
    ConfirmDelete,
    UpdateSubscription,
  },
};
</script>
