<template>
  <div class="vp-pb-6">
    <TheCard class="vp-mt-11 vp-bg-white">
      <template #icon>
        <IconCrm />
      </template>

      <template #default>
        <TheHeader
          title="CRM"
          desc="Expand the functionalities of the most powerful messaging tool and get the most out of your business."
        />
        <div class="vp-mt-6">
          <TheFeatures :value="$options.crmFeatures" />
        </div>
      </template>

      <template #action>
        <VpButtonPillLg
          v-if="!isCrmProductSetup"
          target="_self"
          :href="`${$options.VITE_CRM_URL}/redirect?to=get-started`"
          class="button--right"
          label="Get Started"
          :icon="$options.icons.ArrowRight"
        />
        <VpButtonPillLg
          v-else
          target="_self"
          :href="$options.VITE_CRM_URL"
          class="button--right"
          label="Visit"
          :icon="$options.icons.ArrowRight"
        />
        <p
          v-if="!isCrmProductSetup"
          class="vp-text-xs vp-mt-4 vp-text-center vp-underline vp-text-primary-500 vp-font-light"
        >
          <a
            :href="`${$options.VITE_CRM_URL}/redirect?to=welcome`"
            target="_self"
          >
            Know More
          </a>
        </p>
      </template>
    </TheCard>

    <TheCard class="vp-mt-6 vp-bg-white">
      <template #icon>
        <IconStore />
      </template>

      <template #default>
        <TheHeader
          title="Store"
          desc="Build your e-commerce store to let people place orders directly via WhatsApp."
        />
        <div class="vp-mt-6">
          <TheFeatures :value="$options.storeFeatures" />
        </div>
      </template>

      <template #action>
        <VpButtonPillLg
          v-if="!isStoreProductSetup"
          :href="`${$options.VITE_STORE_URL}/redirect?to=get-started`"
          class="button--right"
          target="_self"
          label="Get Started"
          :icon="$options.icons.ArrowRight"
        />
        <VpButtonPillLg
          v-else
          :href="`${$options.VITE_STORE_URL}`"
          class="button--right"
          target="_self"
          label="Visit"
          :icon="$options.icons.ArrowRight"
        />

        <p
          v-if="!isStoreProductSetup"
          class="vp-text-xs vp-mt-4 vp-text-center vp-underline vp-text-primary-500 vp-font-light"
        >
          <a
            :href="`${$options.VITE_STORE_URL}/redirect?to=welcome`"
            target="_self"
          >
            Know More
          </a>
        </p>
      </template>
    </TheCard>

    <!-- CATALOG -->
    <TheCard class="vp-mt-6 vp-bg-white">
      <template #icon>
        <IconCatalog />
      </template>

      <template #default>
        <TheHeader
          title="Catalog"
          desc="Create a catalog for your store to display and manage your products."
        />
        <div class="vp-mt-6">
          <TheFeatures :value="$options.catalogFeatures" />
        </div>
      </template>

      <template #action>
        <VpButtonPillLg
          v-if="!isStoreProductSetup"
          :href="`${$options.VITE_STORE_URL}/redirect?to=get-started`"
          class="button--right"
          target="_self"
          label="Get Started"
          :icon="$options.icons.ArrowRight"
        />
        <VpButtonPillLg
          v-else
          :href="`${$options.VITE_CATALOG_URL}`"
          class="button--right"
          target="_self"
          label="Visit"
          :icon="$options.icons.ArrowRight"
        />

        <p
          v-if="!isStoreProductSetup"
          class="vp-text-xs vp-mt-4 vp-text-center vp-underline vp-text-primary-500 vp-font-light"
        >
          <a
            :href="`${$options.VITE_CATALOG_URL}/redirect?to=welcome`"
            target="_self"
          >
            Know More
          </a>
        </p>
      </template>
    </TheCard>
  </div>
</template>

<script>
import { ArrowRight } from "icons/icons.js";
import IconStore from "./_icon-store.vue";
import IconCrm from "./_icon-crm.vue";
import IconCatalog from "./_icon-catalog.vue";
import TheHeader from "./_header.vue";
import TheFeatures from "./_features.vue";
import TheCard from "./_card.vue";
const { VITE_STORE_URL, VITE_CRM_URL, VITE_CATALOG_URL } = import.meta.env;
import { mapGetters } from "vuex";

export default {
  VITE_STORE_URL,
  VITE_CRM_URL,
  VITE_CATALOG_URL,
  storeFeatures: [
    "Analytics",
    "Whatsapp Checkout",
    "Receipt",
    "Custom Domain Setup",
  ],
  crmFeatures: ["Analytics", "Ticket Management", "Contacts", "Quick Replies"],
  catalogFeatures: ["Products", "Inventory", "Categories", "Attributes"],
  components: {
    TheCard,
    IconStore,
    IconCrm,
    IconCatalog,
    TheHeader,
    TheFeatures,
  },
  icons: {
    ArrowRight,
  },
  computed: {
    ...mapGetters({
      isStoreProductSetup: "biz/isStoreProductSetup",
      isCrmProductSetup: "biz/isCrmProductSetup",
    }),
  },
};
</script>
