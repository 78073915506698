<template>
  <p>Logging out...</p>
</template>
<script>
import { resetStore } from "@/store/index.js";
import { mapGetters } from "vuex";
import {
  setToken,
  removeCampaign,
  removeStore,
  removeCelloToken,
  removeCelloUcc,
} from "plugins/utils";
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },

  created() {
    if (this.isLoggedIn) {
      this.$track("Logout");
    }

    setToken();
    localStorage.removeItem("vp-last-path");
    removeCampaign();
    removeStore();
    removeCelloToken();
    removeCelloUcc();

    this.$router.push({ name: "login" });
    resetStore();

    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  },
};
</script>
