<template>
  <Loader v-if="loading" />

  <!-- Hydrated -->
  <div
    v-else
    class="vp-grid vp-h-full vp-items-stretch vp-grid-cols-[76px,1fr]"
  >
    <!-- Aside -->
    <aside class="vp-overflow-auto">
      <Sidebar product="web">
        <template #version>
          <span>v{{ $options.version }}</span>
        </template>
      </Sidebar>
    </aside>

    <main class="vp-overflow-auto">
      <div class="vp-flex vp-h-full vp-flex-col">
        <EmailNotVerified />
        <AccountDowngraded />
        <!-- Header -->
        <div class="vp-flex vp-items-stretch vp-border-b vp-relative vp-z-20">
          <Breadcrumbs class="vp-flex-grow" />
          <div class="vp-ml-auto vp-flex vp-items-center vp-px-2">
            <PortalTarget name="header" slim />
          </div>
        </div>
        <!-- Content -->
        <div class="vp-flex-grow vp-overflow-auto">
          <RouterView />
        </div>
      </div>
      <FrillWidget />
    </main>
  </div>
</template>

<script>
import { Menu } from "icons/icons.js";
import { init as initSegment } from "plugins/segment";
import { init as initSplit } from "plugins/split";
import { getToken, getCampaign, setCampaign } from "plugins/utils";
import { mapActions, mapGetters } from "vuex";
import { version } from "../../../package.json";

import Breadcrumbs from "components/src/app/breadcrumbs.vue";
import Sidebar from "components/src/sidebar/Index.vue";
import hydrateQuery from "./get.gql";
import campaigns from "graph/user/campaigns.gql";
// import { sendMessageToExt } from "@/utils";

import AccountDowngraded from "components/src/account/downgraded.vue";
import EmailNotVerified from "components/src/email/not-verified.vue";
import Loader from "components/src/app/loader.vue";
import FrillWidget from "components/src/frill-widget.vue";

export default {
  version,
  icons: {
    Menu,
  },
  components: {
    FrillWidget,
    Loader,
    EmailNotVerified,
    AccountDowngraded,
    Sidebar,
    Breadcrumbs,
  },
  data() {
    return {
      loading: true,
      campaignId: this.$router.app._route.params.campaignId || getCampaign(),
      windowWidth: window.innerWidth,
    };
  },

  async mounted() {
    /**
     * Check for token
     * Set token in localStorage before sending the hydration call
     */
    const token = getToken();
    if (!token) {
      this.$router.push({ name: "login" });
      return;
    }

    /**
     * Check for default campaign ID
     * If not found then get all the campaigns and select 1st one.
     */
    if (!this.campaignId) {
      this.campaignId = await this.getDefaultCampaignId();
    }
    if (this.$router.app._route.params.campaignId) {
      setCampaign(this.campaignId);
    }

    this.startHydration(false);

    this.$root.$on("rehydrate", (campaignId, web, serviceWorker) => {
      if (campaignId) {
        this.campaignId = campaignId;
      } else {
        this.campaignId = this.activeCampaignId;
      }
      setCampaign(this.campaignId);
      this.rehydrate(web, serviceWorker);
    });
  },

  computed: {
    ...mapGetters(["analytics"]),
    ...mapGetters({
      trackingData: "user/trackingData",
      activeCampaignId: "biz/id",
      getStorage: "storage/get",
      isSubscriptionActive: "subscription/isActive",
    }),
  },

  methods: {
    ...mapActions(["hydrate"]),

    startHydration(serviceWorker) {
      if (!serviceWorker) {
        this.loading = true;
      }

      this.hydrate({
        request: this.$query(
          hydrateQuery,
          {
            campaignId: parseInt(this.campaignId),
            userId: parseInt(this.$router.app._route.params.userId),
          },
          {
            noCache: true,
          }
        ),
        version: version,
      })
        .then(() => {
          this.loading = false;
          this.vendorPlugins();

          setTimeout(() => {
            const query = Object.assign({}, this.$route.query);
            const isSignup = query.signup;
            const isLogin = query.login;

            if (isSignup) {
              this.$track("User Signup");
              delete query.signup;
              this.$router.replace({ query }).catch();
            } else if (isLogin) {
              this.$track("Login");
              delete query.login;
              this.$router.replace({ query }).catch();
            }

            this.$track("Open");
          }, 2000);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);

          this.$vayu.notify({
            state: "danger",
            title: "Failed to Login!",
            message:
              "Can not fetch user data. Please login again. If issue persist, kindly contact us.",
            duration: 10000,
          });
          this.$router.push({ name: "logout" });
        });
    },

    rehydrate(web, serviceWorker = true) {
      /**
       * Need to register prototypes in side created hook to have access to component instance.
       * We're connecting with the Chrome Extension
       * When any change is done in admin panel which needs to be updated in extension too,
       * we sent a rehydrate request to extension service worker script.
       *
       * https://developer.chrome.com/docs/extensions/mv2/messaging/
       */
      // sendMessageToExt("rehydrate");

      //Rehydrate web platform only if required
      if (web) {
        this.$cache.evict({
          id: "ROOT_QUERY",
        });
        this.startHydration(serviceWorker);
      }
    },

    async getDefaultCampaignId() {
      return this.$query(campaigns, {})
        .then(({ data }) => {
          const defaultCampaign = data.campaigns.data[0]?.id;
          setCampaign(defaultCampaign);
          return defaultCampaign;
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "logout" });
        });
    },

    vendorPlugins() {
      try {
        initSplit(this.analytics.userId);
        if (this.isSubscriptionActive) {
          // Segment has monthly tracked users limit.therefore sending data of paid users only. learn more at https://segment.com/pricing/
          initSegment(this.analytics);
        }
        this.initClarity();
        this.initOneSignal();
        this.initProfitWell();
      } catch (err) {
        console.error(err);
      }
    },

    initProfitWell() {
      if (window.profitwell) {
        window.profitwell("start", { user_email: this.analytics.email });
      } else {
        console.info("Profit Well is not enabled");
      }
    },

    initOneSignal() {
      if (import.meta.env.VITE_ONESIGNAL !== "true") return;

      this.$OneSignal
        .init({
          appId: import.meta.env.VITE_ONESIGNAL_APP_ID,
          safari_web_id: import.meta.env.VITE_ONESIGNAL_SAFARI_WEB_ID,
          allowLocalhostAsSecureOrigin: true,
          notifyButton: {
            enable: true,
            size: "small",
            position: "bottom-left",
            offset: {
              bottom: "5px",
              left: "5px",
            },
          },
          showCredit: true /* Hide the OneSignal logo */,
          displayPredicate() {
            return this.$OneSignal
              .isPushNotificationsEnabled()
              .then((isPushEnabled) => {
                return !isPushEnabled;
              });
          },
        })
        .then(() => {
          this.$OneSignal.isPushNotificationsEnabled().then((isPushEnabled) => {
            if (!isPushEnabled) {
              this.$OneSignal.showNativePrompt();
            }
          });

          //As per documentation here: https://documentation.onesignal.com/docs/identity-verification
          this.$OneSignal.setEmail(this.analytics.email, {
            emailAuthHash: this.analytics.emailAuthHash,
          });
          this.$OneSignal.setExternalUserId(
            this.analytics.userId.toString(),
            this.analytics.userIdAuthHash
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },

    initClarity() {
      if (import.meta.env.VITE_CLARITY !== "true") return;
      if (window.clarity) {
        window.clarity("set", "user_uuid", this.analytics.uuid);
      }
    },
  },
};
</script>
