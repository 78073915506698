<template>
  <div class="vp-bg-white vp-p-6 vp-rounded-3xl vp-flex vp-flex-col">
    <h4 class="vp-text-xl vp-font-semibold">{{ title }}</h4>
    <p class="vp-mt-3 vp-text-xs vp-text-gray-500 vp-grow">
      {{ desc }}
    </p>

    <div class="vp-mt-6">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
  },
};
</script>

<style scoped></style>
