<template>
  <VpSubShell>
    <template #nav>
      <VpSubNavItem label="My Profile" :to="{ name: 'my-profile' }" />
      <VpSubNavItem label="Change Password" :to="{ name: 'change-password' }" />

      <VpSubNavItem label="Users" :to="{ name: 'users' }" />

      <!--subscription -->
      <VpNavGroup>Subscription</VpNavGroup>
      <VpSubNavItem label="Your Plan" :to="{ name: 'your-plan' }" />
      <VpSubNavItem label="Change Plan" :to="{ name: 'subscription' }" />
    </template>
    <template #default>
      <RouterView />
    </template>
  </VpSubShell>
</template>

<script>
export default {};
</script>
