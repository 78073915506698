<template>
  <div
    class="vp-flex vp-items-center vp-justify-center vp-shrink-0 vp-bg-pink-100 vp-bg-opacity-30 vp-rounded-2xl vp-w-20 vp-h-20 vp-p-5"
  >
    <VpIcon :name="$options.Store" class="vp-text-pink-500 vp-text-6xl" />
  </div>
</template>

<script>
import { Store } from "icons/icons.js";

export default {
  Store,
};
</script>
