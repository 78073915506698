<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M19 13H5v-2h14v2Z"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiMinus",
};
</script>
