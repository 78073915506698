<template>
  <div class="subscription">
    <portal to="header">
      <VyButton
        v-if="isActive && provider != 'appsumo'"
        :icon="$options.icons.Close"
        label="Cancel Subscription"
        :disabled="!canChange"
        @click.native="
          checkPermission('PURCHASE_SUBSCRIPTION', 'upsert', cancel)
        "
        class="button--danger button--muted button--sm button--rounded"
      />
    </portal>

    <div>
      <VpLoaderContainer loading v-if="loading" class="vp-py-8" />
      <div v-else>
        <div class="vp-px-6 vp-py-4 vp-border-b">
          <p class="vp-text-base vp-text-gray-500">
            Select the one that suits best for your business.
          </p>
        </div>
        <div class="vp-flex vp-flex-col vp-flex-col-3 vp-gap-4 vp-w-full">
          <!-- TODO: Fix this Markup -->
          <VpRadioCard
            class="vp-flex vp-flex-wrap vp-gap-4 vp-mx-6"
            :options="subscriptionOptions"
            :value="$route.name"
          >
            <template #default="{ option, value }">
              <router-link :to="option.to">
                <PlanSelectionCard
                  :icon="option.icon"
                  :active="value === option.to.name"
                  :title="option.label"
                  class="vp-w-[350px] vp-px-5 vp-py-5 vp-font-semibold vp-text-sm"
                />
              </router-link>
            </template>
          </VpRadioCard>
        </div>
        <div class="vp-px-6 vp-py-4 vp-border-b vp-flex vp-flex-nowrap">
          <p class="vp-text-base vp-text-gray-500">
            {{ selectedPlan.description }}
          </p>
        </div>
        <router-view :key="$route.name"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { Close, Crm, Store, Vepaar } from "icons/icons.js";
import { mapGetters } from "vuex";

import PlanSelectionCard from "../_PlanSelectionCard.vue";
import subscription from "./mixin.js";

export default {
  icons: {
    Close,
  },
  props: ["value"],
  mixins: [subscription],
  components: {
    PlanSelectionCard,
  },
  data() {
    return {
      subscriptionOptions: [
        {
          label: "Vepaar One (CRM + Store)",
          icon: Vepaar,
          value: "all",
          to: { name: "subscription-all" },
          description:
            "Enable its pro features in sync with your business expansion. From Buzzing Integrations, Ticket Management, Auto-Sync Chats, Personalized Quick Replies, and Export Reports to joining a community of entrepreneurs, get everything under one digital roof.",
        },
        {
          label: "CRM",
          icon: Crm,
          value: "crm",
          to: { name: "subscription-crm" },
          description:
            "The world is running a business on WhatsApp. Have you joined yet? In one go, you can enhance your relationship with revered features such as Vepaar API, Zapier Integration, Salesforce Integration, HubSpot Integration, Staff Accounts, and many more!",
        },
        {
          label: "Store",
          icon: Store,
          value: "store",
          to: { name: "subscription-store" },
          description:
            "Get the perfect dashboard, inclusive of countless features such as Multilingual Support, Ordering on WhatsApp, Payment Integration, Shipping Locations, Custom Domain Setup, Bulk Import Inventory, Staff Account, and the list is endless.",
        },
      ],
    };
  },

  computed: {
    selectedPlan() {
      return this.subscriptionOptions.find(
        (item) => item.to.name == this.$route.name
      );
    },
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
  methods: {
    cancel() {
      this.$router.push({ name: "cancel-subscription" });
      this.$track("Subscription Cancel Clicked");
    },
  },
};
</script>
./index.js ./mixin.js
