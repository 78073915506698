<template>
  <VpSubShell>
    <template #nav>
      <VpSubNavItem
        label="Add Credits"
        :icon="$options.icons.Add"
        :to="{ name: 'credits-add' }"
      />
      <VpSubNavItem
        label="History"
        :icon="$options.icons.History"
        :to="{ name: 'credits-history' }"
      />

      <DocsLink #default="{ credits }">
        <VyButton
          label="Why Credits"
          :icon="$options.icons.Help"
          :href="credits"
          target="vepaar-credits"
          class="button--gray hover:vp-text-gray-500 hover:vp-bg-gray-100 button--rounded button--left button--md vp-w-full"
        />
      </DocsLink>
    </template>
    <template #default>
      <RouterView />
    </template>
  </VpSubShell>
</template>

<script>
import { Add, History, Help } from "icons/icons.js";
import DocsLink from "components/src/docs-link/main.vue";

export default {
  icons: {
    Add,
    History,
    Help,
  },
  components: {
    DocsLink,
  },
};
</script>
