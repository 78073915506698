<template>
  <div
    class="vp-flex vp-flex-grow-1 vp-text-left vp-items-start vp-flex-nowrap vp-p-2 vp-rounded-md vp-cursor-pointer vp-border vp-w-1/3 vp-h-[102px] vp-mt-6"
    :class="{
      'vp-border-gray-200': !active,
      'vp-border-success-500 vp-border-2 vp-bg-success-100': active,
    }"
  >
    <div class="vp-w-full">
      <div>
        <VpIcon
          v-if="true"
          :name="icon"
          class="vp-text-gray-500 vp-w-8 vp-h-8"
          :class="{ 'vp-text-success-600': active }"
        />
      </div>
      <div class="vp-flex-auto vp-text-xl">
        <h5
          class="vp-font-bold vp-mt-2"
          :class="{ 'vp-text-success-600': active }"
        >
          {{ title }}
        </h5>
      </div>
    </div>
    <div>
      <VpIcon
        v-if="active"
        :name="$options.icons.RadioChecked"
        class="vp-text-success-500 vp-w-6 vp-h-6"
      />
      <VpIcon
        v-else
        :name="$options.icons.Radio"
        class="vp-text-gray-200 vp-w-6 vp-h-6"
      />
    </div>
  </div>
</template>

<script>
import { Radio, RadioChecked } from "icons/icons.js";

export default {
  icons: {
    Radio,
    RadioChecked,
  },
  props: {
    active: Boolean,
    title: String,
    icon: {},
  },
};
</script>
