import Vue from "vue";

import App from "./App.vue";
import router from "./router";

import "./plugins/index.js";
import VueApollo from "vue-apollo";
import { apolloClient } from "plugins/apollo";
import "./style/index.css";
import store from "./store/index.js";

Vue.mixin({
  data() {
    return {
      PLATFORM: "WEB",
    };
  },
});

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.prototype.$fbp = (name, payload) => {
  window.fbq?.("track", name, payload);
};

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
