import { mapGetters, mapState } from "vuex";

import CancellationPending from "../_CancellationPending.vue";
import DifferentChannel from "../_DifferentChannel.vue";
import Plan from "../_Plan.vue";
import plans from "./plans.gql";

const PLANS = {
  "subscription-store": "store",
  "subscription-all": "all",
  "subscription-crm": "crm",
  "your-plan": "",
};

export default {
  components: {
    Plan,
    DifferentChannel,
    CancellationPending,
  },

  data() {
    return {
      plans: null,
      features: null,
      duration: null,
      loading: false,
      offer: null,
      isLegacyPlan: false,
    };
  },

  created() {
    this.duration = this.currentDuration;
  },

  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      storeId: "store/id",
      name: "user/name",
      email: "user/email",
      campaignCount: "user/campaignCount",
      isActive: "subscription/isActive",
      planName: "subscription/planName",
      validity: "subscription/validity",
      price: "subscription/price",
      planId: "subscription/planId",
      uuid: "subscription/uuid",
      canChange: "subscription/canChange",
      provider: "subscription/provider",
      isLegacy: "subscription/isLegacy",
    }),
    ...mapState({
      currentDuration: (state) => state.subscription.plan?.duration || "yearly",
    }),
  },

  methods: {
    async get(selectedPlan) {
      this.loading = true;
      await this.$queryPublic(plans, {
        type: !this.isLegacyPlan ? "paddle" : this.provider,
        filters: {
          product: PLANS[selectedPlan],
        },
      })
        .then(({ data: { plans } }) => {
          this.plans = plans.plan;
          this.offer = plans.offerBanner;
          this.features = {};

          this.plans.forEach((plan, index) => {
            plan.features.forEach((feature) => {
              if (!this.features[feature.key]) {
                this.features[feature.key] = [];
              }
              this.features[feature.key][index] = feature.value;
            });
          });
        })
        .catch((err) => {
          console.error(err);
        });
      this.loading = false;
    },
  },
};
